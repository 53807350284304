/* BlogList.css */

.post-list-title {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0 40px;
}

.post-card {
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.post-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.post-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.post-content {
    padding: 20px;
}

.post-title {
    font-size: 1.5em;
    margin: 0;
    color: #333;
}

.post-info {
    font-size: 1em;
    color: #666;
    margin-top: 10px;
}

.delete-button, .post-link {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 15px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
}

.delete-button:hover, .post-link:hover {
    background-color: #0056b3;
}

.delete-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.blog-intro {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-intro h2 {
    color: #0056b3;
    margin-bottom: 15px;
}

.blog-intro p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
}

.blog-intro ul {
    margin-left: 20px;
    list-style-type: disc;
}

.blog-intro li {
    margin-bottom: 10px;
}

#BlogImage {
    width: 300px;
    height: 300px;
}

/* Media query for screens smaller than 768px wide (e.g., tablets) */
@media (max-width: 768px) {
    #BlogImage {
        width: 200px;
        height: 200px;
    }
}

/* Media query for screens smaller than 480px wide (e.g., phones) */
@media (max-width: 480px) {
    #BlogImage {
        width: 150px;
        height: 150px;
    }
}

.search-bar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
