.set-simulator {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .set {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  
  .set li {
    background: #ccc;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .set li.highlight {
    background: #4caf50;
    color: #fff;
  }
  