.flashcard-container {
  perspective: 1000px;
}

.flashcard {
  width: 50vh;
  height: 40vh;
  text-align: center;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  transition: transform 0.6s ease, box-shadow 0.3s ease;
}

.flashcard:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25), 0 8px 28px rgba(0, 0, 0, 0.22);
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
  padding: 20px;
  border-radius: 12px;
}

.front {
  background: linear-gradient(135deg, #a1b7cc, #75a8db);
}

.back {
  background: linear-gradient(135deg, #58b6a3, #3db79f);
  transform: rotateY(180deg);
}

h2 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
}