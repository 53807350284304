/* Profile Page Container */
.profile-page-container {
  display: flex;
  gap: 5%;
  padding: 40px 20px;
  max-width: 100%;
  margin: 2%;
  background-color: #fff3e0;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Profile Container with modern styling */
.profile-container {
  flex: 1;
  max-width: 80%;
  min-width: 300px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

/* Profile Picture Styling */
.profile-pic-placeholder {
  width: 150px;
  height: 150px;
  margin: 0 auto 20px;
  background-color: #f8f9fa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 2px solid #007BFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-pic-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Username and Email Styling */
.username-display {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
}

.profile-email,
.profile-streak,
.profile-questions-answered {
  font-size: 1.1em;
  color: #666;
  margin: 10px 0;
}

/* Button Styling */
.update-button,
.cancel-button {
  font-family: 'Roboto', sans-serif;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  margin: 10px auto;
  display: inline-block;
  width: 60%;
}

.update-button {
  background-color: #007BFF;
}

.update-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.cancel-button {
  background-color: #ff4d4f;
}

.cancel-button:hover {
  background-color: #cc0000;
  transform: translateY(-2px);
}

/* Friends List Styling */
.friends-list-container {
  width: 30%;
  max-width: 300px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.friends-list-container h2 {
  font-size: 1.8em;
  color: #5a3d5c;
}

.friends-list-container ul {
  padding: 0;
  list-style: none;
}

.friends-list-container li {
  background-color: #f8f9fa;
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
  color: #333;
  font-size: 1rem;
  max-width: fit-content;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-page-container {
    flex-direction: column;
    align-items: center;
  }

  .profile-container,
  .friends-list-container {
    width: 90%;
  }
}
