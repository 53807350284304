.chat-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f4f4f9; /* Subtle background color */
  font-family: 'Arial', sans-serif;
  max-width: 100%; /* Limit container width */
}

.sidebar {
  flex: 1;
  background-color: #2c3e50; /* Darker teal for sidebar */
  color: #ffffff;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Slight shadow for separation */
}

.chat-area {
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: #ffffff; /* White background for chat area */
  border-left: 1px solid #e0e0e0; /* Subtle border */
}

.chat-header {
  background-color: #3498db; /* Bright blue */
  color: #ffffff;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for header */
}

.messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add space between messages */
  background-color: #f9f9f9; /* Light background for messages */
  border-top: 1px solid #e0e0e0;
}

.message-bubble {
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  font-size: 18px;
  line-height: 1.5;
}

.sent .message-bubble {
  background-color: #3498db; /* Bright blue for sent messages */
  color: #ffffff; /* White text for contrast */
  align-self: flex-end;
  border-bottom-right-radius: 5px;
}

.received .message-bubble {
  background-color: #eaeaea; /* Light gray for received messages */
  color: #333333; /* Dark text for readability */
  align-self: flex-start;
  border-top-left-radius: 5px;
}

.message-bubble:hover {
  transform: scale(1.02); /* Slight zoom effect on hover */
  transition: transform 0.2s ease-in-out;
}

.message-input {
  background-color: #ffffff;
  padding: 15px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.message-input textarea {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  resize: none;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
}

.message-input button {
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.message-input button:hover {
  background-color: #2176ba; /* Slightly darker blue on hover */
}

.loading {
  text-align: center;
  font-size: 16px;
  color: #3498db;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ellipsis span {
  display: inline-block;
  animation: blink 1.4s infinite;
}

.ellipsis span:nth-child(2) {
  animation-delay: 0.2s;
}

.ellipsis span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%, 20% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.mini-chat {
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #ffffff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.mini-chat .chat-header {
  background-color: #3498db; /* Bright blue */
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.mini-chat .chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9; /* Consistent with main chat area */
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bot-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}