/* src/MatrixMultiplication.css */

.matrix-multiplication {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .matrix-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .matrix {
    display: inline-block;
    margin: 20px;
    border: 1px solid #000;
    padding: 10px;
    transition: transform 1s;
  }
  
  .matrix div {
    display: flex;
  }
  
  .matrix input,
  .matrix span {
    width: 50px;
    height: 50px;
    text-align: center;
    margin: 2px;
  }
  
  #matrixB.flipped {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(90deg);
  }
  
  #matrixB.align {
    transform: rotateY(0deg) translate(100px, 0);
  }
  
  .result-cell {
    position: relative;
    display: inline-block;
    animation: highlight 2s;
  }
  
  @keyframes highlight {
    0% {
      background-color: yellow;
    }
    100% {
      background-color: transparent;
    }
  }
  