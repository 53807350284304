/* AdaptiveAssessments.css */
/* Outer Container for Adaptive Assessments */
.adaptive-container {
    background-color: #e8f5e9; /* Light purple background */
    padding: 40px 20px;
    border-radius: 12px;
    margin: 1.5%;
    max-width: 1500px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    
}
.assessment-list-title {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.assessments-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0 40px;
}

.assessment-card {
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.assessment-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.assessment-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.assessment-content {
    padding: 20px;
}

.assessment-title {
    font-size: 1.5em;
    margin: 0;
    color: #333;
}

.assessment-description {
    font-size: 1em;
    color: #666;
    margin-top: 10px;
}

.add-to-cart-button, .assessment-link {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 15px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
}

.add-to-cart-button:hover, .assessment-link:hover {
    background-color: #0056b3;
}

.add-to-cart-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.irt-cat-intro {
    background-color: #f2f2f2; /* Light grey background for the intro section */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Padding around the content */
    margin: 20px 0; /* Margin above and below the section */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.irt-cat-intro h2 {
    color: #0056b3; /* A shade of blue for the title */
    margin-bottom: 15px; /* Space below the title */
}

.irt-cat-intro p {
    font-size: 1rem; /* Appropriate font size for readability */
    color: #333; /* Dark grey for the text for better contrast */
    line-height: 1.6; /* Line height for better readability */
}

.irt-cat-intro ul {
    margin-left: 20px; /* Indent for the list */
    list-style-type: disc; /* Bullet points for the list items */
}

.irt-cat-intro li {
    margin-bottom: 10px; /* Space below each list item */
}

#Ada{
    width: 300px;
    height: 300px;
}

/* Media query for screens smaller than 768px wide (e.g., tablets) */
@media (max-width: 768px) {
    #Ada {
        width: 200px;
        height: 200px;
    }
}

/* Media query for screens smaller than 480px wide (e.g., phones) */
@media (max-width: 480px) {
    #Ada {
        width: 150px;
        height: 150px;
    }
}
