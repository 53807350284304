/* Friends List Main Container */
.friends-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #e5edf5;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Friends List Title */
.friends-list h3 {
  font-size: 1.8em;
  color: #3d455c;
  text-align: center;
  margin-bottom: 15px;
}

/* Individual Friend Card */
.friend-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  text-align: center;
}

.friend-card:hover {
  transform: translateY(-2px);
}

/* Friend Name Styling */
.friend-card h4 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
}

/* Streak Info Styling */
.friend-card p {
  margin: 5px 0 0;
  color: #666;
  font-size: 0.9em;
}

/* Friends List Container */
.friends-list ul {
  padding: 0;
  list-style: none;
}

/* Friend Item Row in List */
.friend-item {
  display: flex;
  align-items: center;
  background-color: #5b8aa0;
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
  color: #333;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Status Indicator Light */
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.online {
  background-color: #28a745; /* Green for online */
}

.offline {
  background-color: #dc3545; /* Red for offline */
}

/* Friend Name Text */
.friend-name {
  font-weight: bold;
  margin-right: 8px;
}

/* Status Text Styling */
.status-text {
  font-size: 0.9rem;
}

.online-text {
  color: #28a745; /* Green text for online */
}

.offline-text {
  color: #dc3545; /* Red text for offline */
}

/* Responsive Design */
@media (max-width: 768px) {
  .friends-list {
    padding: 15px;
  }

  .friend-card {
    padding: 10px;
  }

  .friend-card h4 {
    font-size: 1.1em;
  }

  .status-indicator {
    width: 8px;
    height: 8px;
  }

  .friend-name {
    font-size: 0.95em;
  }
}
