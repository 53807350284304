.question-form-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .question-form {
    display: flex;
    flex-direction: column;
  }
  
  .question-form label {
    margin-bottom: 0.5rem;
    color: #665;
  }
  
  .question-form input[type="text"],
  .question-form textarea,
  .question-form select {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-actions {
    text-align: center;
  }
  
  .submit-button {
    background-color: #5cb85c;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #4cae4c;
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .form-feedback {
    margin-top: 1rem;
  }
  
  .success-messages {
    color: #3c763d;
  }
  
  .error-messages {
    color: #a94442;
  }
  