.chat-container{
    display:flex;
    flex-direction: row; 
    height:200vh;
}

.sidebar {
   flex: 1;
   background-color:teal; 
   color: #ffffff;
   padding: 20px;
   overflow-y: auto;
}

.chat-area {
    flex:3;
    display:flex;
    flex-direction: column;
}

.chat-header{
    background-color: #3498db;
    color: #ffffff;
    padding: 15px;
    text-align: center;
}

.messages{
    flex:1;
    padding:20px;
    overflow-y: auto;
}

.message{
    margin-bottom: 15px;
}

.sent{
    display:flex;
    justify-content: flex-end;
}

.received{
    display:flex;
    justify-content: flex-start;
}

.message-bubble{
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
}

.sent .message-bubble{
    background-color: #3498bd;
    color: #ffffff;
    border-bottom-right-radius: 0;
}

.received .message-bubble{
    background-color: #eaeaea;
    border-top-right-radius: 0;
}

.message-input{
    background-color: #ffffff;
    padding:15px;
    border-top: 1px solid #e0e0e0;
    display:flex;
    align-items: center;

}

.message-input textarea{
    flex: 1;
    border: none;
    border-radius: 5px;
    padding: 10px;
    resize: none;

}

.message-input button{
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding: 10px, 20px;
    margin-top:20px;
}

