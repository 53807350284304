/* Main Container */
.aboutUsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    background-color: #e5e5f5; /* Soft lavender background */
    color: #6a1b9a; /* Deep purple for text */
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    margin: 2%;
}

/* General Content Styling */
.aboutUsContent {
    width: 80%;
    max-width: 1200px;
    text-align: center;
    padding: 50px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    margin-bottom: 40px;
}

/* Team Member Container */
.teamMember {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    padding: 30px;
    background-color: #f0f9ff; /* Soft blue background for individual members */
    border-radius: 15px;
    width: 90%;
    max-width: 900px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Member Image */
.memberPhoto {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-bottom: 25px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.memberPhoto:hover {
    transform: scale(1.05);
}

/* Member Name and Title */
.memberName {
    font-size: 26px;
    color: #004a99; /* Deep blue */
    margin-bottom: 10px;
    font-weight: bold;
}

.memberTitle {
    font-size: 20px;
    color: #666666;
    margin-bottom: 20px;
    font-style: italic;
}

/* Bio Styling */
.memberBio {
    font-size: 18px;
    line-height: 1.8;
    text-align: left;
    color: #333333;
    max-width: 800px;
}

/* Headings within Bio */
.memberBio p {
    margin-bottom: 15px;
}

/* Hover Effect for Interactive Sections */
.teamMember:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

/* About Us Headings */
h1, h2 {
    font-size: 2.2em;
    color: #004a99;
    font-weight: bold;
    margin-bottom: 20px;
}

p {
    font-size: 1.1em;
    line-height: 1.7;
}

/* Responsive Design */
@media (max-width: 768px) {
    .aboutUsContent, .teamMember {
        width: 100%;
        padding: 20px;
    }

    .memberPhoto {
        width: 150px;
        height: 150px;
    }

    .memberName {
        font-size: 22px;
    }

    .memberTitle {
        font-size: 18px;
    }

    .memberBio {
        font-size: 16px;
    }
}
