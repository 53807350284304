.periodic-table {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-gap: 5px;
  padding: 10px;
  background-color: #f0f0f0;
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.element h4 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.element p {
  margin: 5px 0 0;
}

.element.color-1 {
  background-color: #ff9999;
}

.element.color-2 {
  background-color: #ffcc99;
}

/* Additional color classes as needed */
