/* bohrstyles.css */
/* Container styles */

/* Outer Container */
.outer-lab-container {
    background-color: #dbe5fb; /* Soft lavender background to complement the inner container */
    padding: 40px 20px; /* Creates space around the inner container */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height */
    margin: 1.5%;
}

.lab-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

/* Title styling */
.lab-title {
    font-size: 3em;
    margin-bottom: 20px;
    color: #2c3e50;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #3498db;
    display: inline-block;
    padding-bottom: 10px;
}

/* Section container */
.lab-sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* Section styling */
.lab-section {
    flex: 0 0 48%; /* Two sections per row */
    margin-bottom: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
    border-left: 5px solid #3498db;
    position: relative;
}

.lab-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.lab-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(52, 152, 219, 0.1) 25%, rgba(0, 0, 0, 0));
    border-radius: 10px;
    z-index: -1;
}

/* Section titles */
.lab-section h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #34495e;
    font-family: 'Orbitron', sans-serif;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #3498db;
    padding-bottom: 5px;
}

/* List styling */
.lab-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* List item styling */
.lab-list li {
    margin-bottom: 15px;
}

.lab-link {
    text-decoration: none;
    color: #2980b9;
    font-size: 1.2em;
    display: block;
    padding: 10px 15px;
    background-color: #ecf0f1;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lab-link:hover {
    background-color: #3498db;
    color: #fff;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Icons for links (Optional, you can use a library like Font Awesome) */
.lab-link::before {
    content: '\1F4C8'; /* Default icon (replace with appropriate icons) */
    margin-right: 10px;
    font-size: 1.2em;
    color: #3498db;
    transition: color 0.3s ease;
}

.lab-link:hover::before {
    color: #fff;
}

/* Responsive design */
@media (max-width: 768px) {
    .lab-section {
        flex: 0 0 100%; /* One section per row */
    }
}
