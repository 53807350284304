.Cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  max-height: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #7fb3ea;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.subscription-selection {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.subscription-option {
  padding: 10px 20px;
  border: 1px solid #acccee;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subscription-option.selected {
  background-color: #007bff;
  color: blue;
}

.proceed-to-checkout {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.2s ease;
}

.proceed-to-checkout:hover {
  background-color: #217a3c;
}

.discount-code-section input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  flex-grow: 1;
}

.discount-code-section button {
  background-color: #0069d9;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.discount-code-section button:hover {
  background-color: #0056b3;
}

/* Add a max-width for responsive design */
@media only screen and (max-width: 768px) {
  .subscription-selection {
    flex-direction: column;
  }

  .discount-code-section {
    flex-direction: column;
  }

  .discount-code-section input,
  .discount-code-section button {
    width: 100%;
    margin: 5px 0;
  }
}
