/* Main Container */
.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh; /* Extra space at the bottom for smooth scrolling */
    gap: 20px;
    margin-bottom: 0%;
}

/* General Section Styles */
.fade-in-section {
    width: 80%;
    max-width: 1200px;
    padding: 40px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.8s ease, transform 0.8s ease;
    transform: translateY(20px);
}
.section-tools-heading{
    color: #01579b;
}
.section-top-down-heading{
    color: #257D32;
}
.section-real-life-heading{
    color:#E65100
}

.section-stem-heading{
    color:#6A1B9A
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0);
}

.section-video {
    width: 100%;
    max-height: 400px;
    border-radius: 8px;
    margin-top: 20px;
}

/* Section-specific Styles */
.section-welcome {
    background-color: #f0f9ff;
    text-align: center;
    color: #f0f5ff;;
    padding: 60px 20px;
    border-radius: 12px;
}

.section-tools {
    background-color: #e3f2fd;
    color: #01579b;
    padding: 60px 20px;
    border-radius: 12px;
}

.section-top-down {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 60px 20px;
    border-radius: 12px;
}

.section-real-life {
    background-color: #fff3e0;
    color: #e65100;
    padding: 60px 20px;
    border-radius: 12px;
}

.section-stem {
    background-color: #f3e5f5;
    color: #6a1b9a;
    padding: 60px 20px;
    border-radius: 12px;
}

/* Headings and Button */
h1, h2 {
    font-size: 2.4em;
    font-weight: bold;
    margin-bottom: 15px;
}

p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 15px;
}

button {
    background-color: #004a99;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

button:hover {
    background-color: #003366;
    transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .fade-in-section {
        width: 90%;
        padding: 30px 15px;
    }

    h1, h2 {
        font-size: 1.8em;
    }

    p {
        font-size: 1em;
    }
}
