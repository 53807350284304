.loop-visualizer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loop-visualizer {
    display: flex;
    justify-content: space-between;
    width: 500px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .spot {
    width: 50px;
    height: 50px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .iteration-input {
    margin: 20px;
    padding: 5px;
    font-size: 16px;
  }
  
  .code-display pre {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
  }
  