/* Main Outer Container */
.video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaecdd; /* Light blue-grey background */
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  margin: 2%;
}

/* Inner Content Container */
.video-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 800px;
}

/* Video Frame */
.video-frame {
  width: 100%;
  height: 450px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Metadata Styling */
.video-metadata {
  text-align: center;
  padding: 20px 0;
  color: #333333;
}

.video-title {
  font-size: 1.8em;
  font-weight: bold;
  color: #003366; /* Darker navy for a professional look */
  margin-bottom: 8px;
}

.video-description {
  font-size: 1.1em;
  line-height: 1.6;
  color: #444444; /* Subtle dark grey for readability */
}

/* Loading and Error States */
.video-loading, .video-error {
  font-size: 1.5em;
  color: #b00020; /* Red tone for error visibility */
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .video-frame {
      height: 280px; /* Adjust height for smaller screens */
  }

  .video-title {
      font-size: 1.5em;
  }

  .video-description {
      font-size: 1em;
  }

  .video-content {
      padding: 15px;
  }
}
