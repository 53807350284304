/* General styles */
.cat-container-background {
  background-color: #f0f9ff; /* Light blue to match .section-welcome */
  min-height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem #00000026;
  padding: 20px;
  color: #333; /* Dark text for readability */
  margin: 1.5%;
}

/* Outer Assessment Container */
.outer-assessment-container {
  background-color: #f3e5f5; /* Light purple for outer background */
  min-height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin: 1.5%;
  
}
/* Assessment Container */
.assessment-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Assessment Heading */
.assessment-heading {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
}

/* Questions and Options */
.assessment-question-container {
  padding: 1rem;
  background-color: #f8f9fa;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.assessment-question {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #34495e;
}

.assessment-question-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 1rem;
  border-radius: 8px;
}

/* Assessment Choices */
.assessment-choice {
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.assessment-choice:hover {
  background-color: #ecf0f1;
  border-color: #bdc3c7;
}

.assessment-choice input[type='radio'],
.assessment-choice input[type='checkbox'] {
  margin-right: 0.75rem;
}

.assessment-choice label {
  width: 100%;
  cursor: pointer;
}

/* Choice Images */
.assessment-choice-image {
  max-width: 300px;
  max-height: 300px;
  display: block;
  margin: 10px 0;
}

/* Navigation Buttons */
.assessment-navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.assessment-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.assessment-button:hover {
  opacity: 0.9;
}

/* Previous and Submit Buttons */
.assessment-previous-button,
.assessment-submit-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  background-color: #5cb85c;
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  width: auto;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.assessment-previous-button:hover,
.assessment-submit-button:hover,
.assessment-submit-button:focus {
  background-color: #449d44;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.assessment-submit-button:active {
  background-color: #398439;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

/* Main Layout */
.cat-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

/* Timer Display */
.timer-display {
  font-size: 1.2em;
  font-weight: bold;
  color: #01579b;
  margin-bottom: 15px;
}

/* Image Container */
.cat-image-container {
  margin-top: 20px;
  text-align: center;
}

.cat-image-container img {
  max-width: 150px;
  border-radius: 8px;
}

/* Graph Container */
.cat-graph-container {
  margin-top: 30px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.graphContainer {
  width: 100%;
  max-width: 900px;
  margin: auto;
  padding: 20px;
}

/* Small Screen Adjustments */
@media (max-width: 768px) {
  .cat-graph-container, .graphContainer {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .cat-graph-container, .graphContainer {
    padding: 10px;
  }
}

/* Heading and Choices */
.cat-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #0d2743;
  text-align: center;
}

.cat-choices {
  margin-bottom: 20px;
}

.cat-choices label {
  padding: 10px;
  background-color: #e9ecef;
  display: block;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cat-choices input[type='radio']:checked + label {
  background-color: #007bff;
  color: white;
}

/* Submit Button */
.cat-submit-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin: 20px auto;
  width: 50%;
  border-radius: 25px;
  transition-duration: 0.4s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cat-submit-button:hover {
  background-color: white;
  color: #28a745;
  border: 2px solid #28a745;
}

/* Completion Message */
.ada-completion-message {
  padding: 15px;
  margin-top: 20px;
  background-color: #f4f4f4;
  border-left: 5px solid #9b59b6;
  font-style: italic;
  color: #333;
}

.ada-message p {
  color: #9b59b6;
  font-style: italic;
  text-align: center;
  margin-top: 10px;
}
