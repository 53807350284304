/* Assessment Review Page General Styles */
.assessment-review-container {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    width: 80%;
    margin: auto;
    overflow: hidden;
}

/* Header Styles */
.assessment-review-header {
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
}

/* List Styles */
.assessment-review-list {
    list-style-type: none;
    padding: 0;
}

.assessment-review-list-item {
    background: #fff;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Question and Answer Styles */
.assessment-review-question {
    font-weight: bold;
}

.assessment-review-user-answer,
.assessment-review-correct-answer {
    color: #31708f;
}

.assessment-review-explanation {
    color: #6c757d;
    font-style: italic;
}

/* Loading Styles */
.assessment-review-loading {
    text-align: center;
    font-size: 1.2rem;
}
