/* Output Container */
.output-container {
    width: 100%;
    background: #181818;
    border-radius: 8px;
    padding: 20px;
    color: #fff;
    font-family: "Fira Code", monospace;
  }
  
  /* Header Bar */
  .header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .output-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #e6e6e6;
  }
  
  /* Run Button */
  .run-button {
    background: #007acc;
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .run-button:hover {
    background: #005fa3;
  }
  
  .run-button:disabled {
    background: #555;
    cursor: not-allowed;
  }
  
  /* Output Box */
  .output-box {
    height: 300px;
    overflow-y: auto;
    background: #0e0e0e;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #333;
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .output-box.success {
    border-color: #00c851;
  }
  
  .output-box.error {
    border-color: #ff4444;
  }
  
  .output-line {
    color: #e6e6e6;
  }
  
  .placeholder-text {
    color: #777;
    font-style: italic;
  }
  