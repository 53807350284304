.invite-link-container {
    text-align: center;
    margin: 20px;
  }
  
  .copy-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .copy-button:hover {
    background-color: #0056b3;
  }
  
  .qrcode-container {
    margin-top: 20px;
  }
  