.periodic-table {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-template-rows: repeat(7, 50px);
  gap: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  
}
.periodic-table > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 4px;
  transition: all 0.2s;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.periodic-table > div:hover {
  background-color: #f0f0f5;
}

  
  .control-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: absolute;
    bottom: 1%;
    left: 5%

  }
  
  .text-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px #0000001a;
    color: #333;
    font-family: Roboto, sans-serif;
    margin: 20px auto;
    max-width: 100%;
    padding: 15px;
    text-align: center;
}
  
  .control-button:hover {
    background-color: #0056b3;
  }
  

  .threejs-container {
    position: relative;
    right: 20%;
    max-width: fit-content;

  }