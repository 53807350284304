/* MoleculeViewer.css */

/* Container for the text and instructions */
.text-container {
    color: #333;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 800px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    font-family: 'Roboto', sans-serif;
}

/* Header styles */
.text-container h2 {
    font-family: 'Orbitron', sans-serif; /* Digital-looking font */
    color: #007BFF; /* Primary color */
    margin-bottom: 10px;
}

/* Paragraph styles */
.text-container p {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Container for the controls */
.controls-container {
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive columns */
    gap: 20px;
    max-width: 800px;
}

/* Label and input styles */
.controls-container label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}

.controls-container input {
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    font-family: 'Roboto', sans-serif;
    transition: box-shadow 0.2s;
    width: 100%;
    box-sizing: border-box;
}

/* Input focus styles */
.controls-container input:focus {
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Highlight shadow */
    border-color: #007BFF; /* Highlight border */
}

/* Button styles */
.controls-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    transition: background-color 0.2s, transform 0.2s;
    width: 100%;
    max-width: 150px;
    grid-column: span 2; /* Span full row in smaller screens */
}

/* Button hover styles */
.controls-container button:hover {
    background-color: #0056b3;
    transform: translateY(-2px); /* Lift effect */
}

/* Viewer styles */
.viewer_3Dmoljs {
    border-radius: 10px;
    overflow: hidden; /* Ensure child elements are contained */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Shadow for depth */
    background: #f8f9fa; /* Light background */
}

/* Responsive styles */
@media (max-width: 600px) {
    .text-container {
        padding: 10px;
        margin: 10px auto;
        max-width: 100%;
    }

    .controls-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust for smaller screens */
    }
    
    .controls-container label {
        margin-bottom: 5px;
    }
    
    .controls-container input {
        width: 100%; /* Full width for inputs on mobile */
    }
    
    .controls-container button {
        width: 100%; /* Full width for button on mobile */
        grid-column: span 1; /* Span one column */
    }
}
