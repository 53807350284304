/* Existing styles (unchanged) */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f8;
}

/* Main app container */
.anatomy-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
}

/* Header styling */
.app-header {
  background-color: white;
  color: white;
  padding: 20px 0;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.app-header h1 {
  font-size: 2.5rem;
  margin: 0;
}

/* Description section */
.app-description {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 70%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
}

.app-description ul {
  list-style-type: none;
  padding: 0;
}

.app-description li {
  margin: 10px 0;
  font-size: 1rem;
}

/* Control buttons */
.app-controls {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.app-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 15px 25px;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.app-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* 3D Canvas styling */
.app-canvas {
  margin-top: 20px;
  width: 100%;
  height: 60vh;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Slider styling */
.app-slider {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#scale-slider {
  width: 200px;
}

/* Tooltip styling */
.tooltip {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none;
}

.loading-container {
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
  color: white;
  font-family: Arial, sans-serif;
  pointer-events: none; /* Allow interaction with the canvas while loading */
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for phone screens (up to 600px) */
@media (max-width: 700px) {
  /* Reduce the header title size */
  .app-header h1 {
    font-size: 1.8rem;
  }

  /* Make the description area take more width and reduce padding */
  .app-description {
    width: 90%;
    padding: 15px;
    font-size: 0.95rem;
  }

  /* Shrink the height of the 3D canvas on small screens */
  .app-canvas {
    height: 30vh;
    width: 10vh;
  }

  /* Adjust the controls layout if necessary */
  .app-controls {
    flex-direction: column;
    gap: 10px;
  }

  .app-button {
    width: 100%;
    font-size: 0.9rem;
  }
}
