/* General Container */
.code-editor-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #121212;
  min-height: 100vh;
}

/* Side-by-side layout for larger screens */
.editor-output-container {
  display: flex;
  width: 90%;
  gap: 20px;
}

/* Editor Styling */
.editor-container {
  flex: 1;
  background: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
}

/* Output Container */
.output-container {
  flex: 1;
  background: #222;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
}

/* Run Button */
.run-button {
  margin-top: 10px;
  padding: 12px 20px;
  background-color: #6200ea;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.run-button:hover {
  background-color: #3700b3;
}

/* Instructional Container */
.instructional-container {
  background-color: #f0f4f8;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  width: 90%;
  max-width: 800px;
}

/* Instructional Title */
.instructional-title {
  color: #007acc;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* Instructional Text */
.instructional-text {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: center;
}

/* Instructional List */
.instructional-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.instructional-list li {
  background-color: #007acc;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.instructional-list li:hover {
  background-color: #005b99;
}

/* Instructional Note */
.instructional-note {
  font-size: 14px;
  font-style: italic;
  color: #555;
  text-align: center;
}

/* Responsive Design for Phones */
@media (max-width: 768px) {
  .editor-output-container {
      flex-direction: column;
      width: 100%;
      gap: 10px;
  }

  .editor-container, 
  .output-container {
      width: 100%;
      padding: 15px;
      border-radius: 6px;
  }

  .code-editor-layout {
      padding: 10px;
  }

  .run-button {
      width: 100%;
      font-size: 18px;
      padding: 14px;
  }

  .instructional-container {
      width: 100%;
      padding: 15px;
  }

  .instructional-title {
      font-size: 22px;
  }

  .instructional-text {
      font-size: 15px;
  }

  .instructional-list {
      flex-direction: column;
      align-items: center;
  }

  .instructional-list li {
      width: 80%;
      text-align: center;
  }
}
