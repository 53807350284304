.keyboard {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 8px;
    padding: 20px;
    background: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 360px; /* Adjust width as needed */
    margin: auto;
}

.key {
    background: #000000;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.key:hover {
    background-color: #ddd;
}

.key:active {
    background-color: #ccc;
}

