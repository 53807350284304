

  .Cart{
    background-color: #dfebf7;
  }


  #Cartincheckout{ max-height: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fbfbfb;}
  .cart-summary {
    width: 100%;
    margin: 30px auto;
    margin-left: 275px;

    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f8f8f8;
  }
  
  .cart-list {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .Cartitename{
    font-size: 1.5em;
  }

  .cart-item {
    display: flex;
    flex-direction: column; /* Stack the elements vertically */
    align-items: flex-start; /* Align items to the left */
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
  }
  
  .cart-item span {
    margin-bottom: 5px; /* Add some space between the title and the button */
  }
  
  .cart-item button {
    align-self: center; /* Center the button below the title and price */
    margin-top: 10px; /* Add some space above the button */
  }
  
  .cart-item button:hover {
    background-color: #c82333;
  }
  
  .cart-total {
    text-align: right;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  @media only screen and (max-width: 600px) {
    .Cart {
      align-items: center; /* Center align the main container */
    }
    
    .cart-summary {
      width: 90%; /* Use a percentage width for responsiveness */
      margin: 30px 0; /* Adjust margin to add space on the top and bottom */
      padding: 20px; /* Keep padding to maintain spacing inside the container */
    }
  
    #payment-form {
      width: 90%; /* Use a percentage width for responsiveness */
      margin: 0; /* Remove fixed margin */
      padding: 20px; /* Consistent padding with cart summary */
      box-shadow: none; /* Optional: remove the shadow if it feels too heavy */
      margin-left: 0px;
      color: #ddd;
    }
    
    .cart-item button {
      padding: 12px; /* Increase padding for a larger touch area */
    }
  
    .cart-item {
      padding: 10px 0; /* Adjust padding for each item */
    }
  
    .cart-total {
      text-align: center; /* Center-align the total */
      font-size: 1em; /* Adjust font size if necessary */
      margin-top: 15px;
    }
  
    /* Adjust the button size to be more mobile-friendly */
    .Cart button {
      padding: 12px;
      font-size: 1em; /* Adjust font size to ensure it's readable */
    }
    
    /* Ensure the spinner does not take too much space */
    .Cart .spinner {
      width: 20px;
      height: 20px;
    }

    .Cart #payment-form {
      margin-left: 0px;
     
    }

    
  }
  
  
  .cart-total {
    text-align: right;
    font-size: 1.2em;
    margin-top: 15px;
  }
  
  .Cart #payment-form {
    width: 100%;
    min-width: 100%;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    margin-left: 46px;
}
  
  .Cart #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  .Cart #payment-element {
    margin-bottom: 24px;
  }
  
  .Cart button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  .Cart button:hover {
    filter: contrast(115%);
  }
  
  .Cart button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  .Cart .spinner,
  .Cart .spinner:before,
  .Cart .spinner:after {
    border-radius: 50%;
  }
  
  .Cart .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .Cart .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .Cart .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    .Cart #payment-form {
      width: 80vw;
      min-width: initial;
      margin-left: 0px;
      
    }
  }
  
  .checkout-container {
    display: flex;
    flex-direction: column; /* Stack vertically */
    align-items: center;
  }
  
  .cart-summary {
    margin-bottom: 20px; /* Add space between the cart summary and the payment form */
  }

  #payment-form {

    
    margin-top: 50px;

  }
  
  /* For a side-by-side layout, use the following: */
  @media (min-width: 768px) { /* Adjust based on your responsive design breakpoints */
    .checkout-container {
      flex-direction: row; /* Align side by side for wider screens */
      justify-content: center;
    }
  
    .cart-summary {
      margin-right: 20px; /* Add space between the cart summary and the payment form */
      flex: 1; /* Adjust the flex grow/shrink/basis as needed */
    }
  }

/* Styles for the cart container */
.cartstuff {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-width: 100%;
  margin-right: 30%;
}

/* Header for the cart items */
.Cartitems {
  color: #333;
  margin-bottom: 15px;
}

/* Style for each cart item */
.Cartite {
  background: #fff;
  border-bottom: 1px solid #eee;
  padding: 10px 15px;
  list-style-type: none;
}

/* Style for the cart item name */
.Cartitename {
  font-size: 1.2rem;
  color: #333;
  margin: 5px 0;
}

/* Style for the cart total section */
.cart-total2 {
  text-align: center;
  padding: 15px 20px;
  background: #f7f7f7;
  border-top: 1px solid #eee;
}

/* Style for the total cost */
.cart-total2 strong {
  font-size: 1.4rem;
  color: #333;
}

.checkoutcartContainer {
  margin-left: 25%;
}

subscription-selection {
  display: flex;
  justify-content: space-around;
  padding: 1em;
}

.subscription-option {
  border: 1px solid #ddd;
  padding: 1em;
  border-radius: 5px;
  cursor: pointer;
}

.subscription-option.selected {
  border-color: #007bff;
  background-color: #e7f1ff;
}

.proceed-to-checkout {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
}

#submit{
    
        background: #5469d4;
        font-family: Arial, sans-serif;
        color: #ffffff;
        border-radius: 4px;
        border: 0;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        width: 100%;
    
}