/* AbilityGraph.css */

/* Container styles */
.graphContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    background: #1a1a2e; /* Dark background for a digital look */
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
}

/* Title styling */
.app-title {
    color: #00d4ff; /* Bright, digital color */
    font-family: 'Orbitron', sans-serif; /* Digital-looking font */
    text-align: center;
    margin-bottom: 20px;
}

/* Digital display styling */
.digital-display {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(145deg, #1c1c30, #2a2a42); /* Slight gradient for depth */
    padding: 15px 30px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3), inset 0 0 10px rgba(53, 91, 241, 0.3);
    transform: perspective(1000px) rotateX(5deg); /* Slight 3D effect */
}

.digital-score {
    font-family: 'Orbitron', sans-serif; /* Digital-looking font */
    color: #00d4ff; /* Updated color to match .ada-message */
    font-size: 3em;
    text-shadow: 0 0 10px rgba(0, 212, 255, 0.7), 0 0 20px rgba(0, 212, 255, 0.5);
    animation: pulse 1.5s infinite; /* Pulsing effect */
}

/* Pulsing animation */
@keyframes pulse {
    0% { text-shadow: 0 0 10px rgba(0, 212, 255, 0.7), 0 0 20px rgba(0, 212, 255, 0.5); }
    50% { text-shadow: 0 0 20px rgba(0, 212, 255, 1), 0 0 30px rgba(0, 212, 255, 0.8); }
    100% { text-shadow: 0 0 10px rgba(0, 212, 255, 0.7), 0 0 20px rgba(0, 212, 255, 0.5); }
}

/* Ada's message styling */
.ada-message p {
    font-size: 18px;
    color: #00d4ff;
    text-align: center;
    background: linear-gradient(90deg, #00d4ff, #ffa500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Roboto Mono', monospace; /* Monospace font for digital feel */
    animation: fadeIn 2s ease-in-out;
}


/* Keyframes for fading animation */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Plotly graph styling */
.plotly {
    background: transparent !important; /* Transparent background */
}

/* Font imports */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600&family=Roboto+Mono:wght@400;500;700&display=swap');

/* Responsive design for mobile devices */
@media (max-width: 600px) {
    .graphContainer {
        padding: 15px;
    }

    .app-title {
        font-size: 1.5em;
        margin-bottom: 20px;
    }

    .digital-score {
        font-size: 2.5em; /* Adjust size for mobile */
    }
}
