/* General Header Styles */
.Header {
  display: flex;
  background: linear-gradient(135deg, #a6c6e8, #88a6d5); /* Gradient for depth */
  gap: 20vh;
  padding: 20px;
  height: auto;
  justify-content: center;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for elevation */
  border-radius: 10px;
  margin-left: 0.5%;
  margin-right:0.5%
}

/* Right Column */
.rightcolumnheader {
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40%;
}

#HeaderLogo {
  max-width: 100%;
  height: 100%;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for the logo */
  border-radius: 8px; /* Optional for a softer look around the logo */
}

/* Left Column */
.leftcolumn {
  order: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
}

/* Left Column Rows */
.leftcolumnrow1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
}

.header-video {
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Light shadow for the video */
  margin-bottom: 8%;
}

/* Content Row */
.leftcolumnrow2 {
  text-align: center;
}

#GreetingText {
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  margin: 0;
}

.HeaderSubtitle {
  color: #ffffff;
  font-size: 1em;
}

/* Call-to-action Button */
.HeaderCta {
  display: inline-block;
  font-weight: bold;
  color: #004a99;
  background-color: #ffc107;
  padding: 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Button shadow for depth */
}

.HeaderCta:hover {
  background-color: #e0a806;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25); /* Enhanced shadow on hover */
}

/* Navbar Styles */
.navbar {
  background-color: #088d9e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  transition: all 0.3s ease;
  height: 5.9vh;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.navbar-brand {
  font-weight: 600;
  font-size: 1.5rem;
  color: #f0f0f0;
}

.navbar .nav-link {
  font-weight: 700;
  color: #ffffff;
  font-size: 0.9rem;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  transition: color 0.2s ease;
}

.navbar .nav-link:hover .text-white {
  color: #ffc107;
}

.text-white-new{
  color: white;
  font-size: 0.9rem;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  font-weight: 700;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .Header {
    flex-direction: column;
    height: auto;
    padding: 15px;
    gap: 10px;
    text-align: center;
  }

  /* Right Column on Mobile */
  .rightcolumnheader {
    order: 1;
    max-width: 80%;
    margin: 0 auto;
  }

  #HeaderLogo {
    max-width: 60%;
    margin: 10px auto;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15); /* Softer shadow for mobile */
  }

  /* Left Column on Mobile */
  .leftcolumn {
    order: 2;
    width: 100%;
    align-items: center;
  }

  .leftcolumnrow1 {
    height: auto;
  }

  .header-video {
    max-width: 80%;
    margin: 0 auto;
  }

  #GreetingText {
    font-size: 1.5em;
  }

  .HeaderSubtitle {
    font-size: 1em;
  }

  .HeaderCta {
    width: 80%;
    padding: 10px;
    margin: 15px auto;
    text-align: center;
  }

  .navbar {
    padding: 0 10px;
    justify-content: center;
    height: 8vh;
  }

  .navbar-brand {
    font-size: 1.5rem;
  }


}
