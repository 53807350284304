/* Contact Us Page Styles */
.contact-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #ece9e6, #ffffff); /* Soft gradient background */
}

.contact-us-content {
    text-align: center;
    background: white;
    padding: 50px 40px; /* Added more padding for a spacious look */
    border-radius: 15px; /* Rounded corners for modern look */
    box-shadow: 0 10px 20px rgba(0,0,0,0.2); /* Deeper shadow for a subtle 3D effect */
    max-width: 500px; /* Slightly wider content box */
    transition: transform 0.3s ease; /* Animation for hover effect */
}

.contact-us-content:hover {
    transform: translateY(-5px); /* Slight raise effect on hover */
}

.contact-us-content h1 {
    color: #333;
    margin-bottom: 25px;
    font-family: 'Helvetica Neue', sans-serif; /* Stylish font */
    font-size: 2.5rem; /* Larger title */
}

.contact-email {
    color: #1a73e8;
    font-size: 1rem;
    text-decoration: none;
    margin: 25px 0;
    display: inline-block;
    font-family: 'Helvetica Neue', sans-serif; /* Stylish font */
}

.contact-email:hover {
    text-decoration: underline;
    color: #0f62fe; /* Slightly darker shade on hover */
}

.response-time {
    color: #6c757d;
    margin-top: 25px;
    font-style: italic;
    font-size: 1rem;
    font-family: 'Helvetica Neue', sans-serif; /* Stylish font */
}

/* Additional styles for a responsive form (if you decide to add one) */
.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box; /* Makes sure padding doesn't affect width */
}

.contact-form button {
    width: 100%;
    padding: 10px;
    border: none;
    background: #0f62fe;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.contact-form button:hover {
    background: #1a73e8;
}
