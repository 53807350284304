/* General styles for search bar */
.search-bar {
  width: 90%;
  padding: 10px 15px;
  margin: 20px auto;
  display: block;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}
.video-list-outer{
  background-color: #eaecdd;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin: 2%;
  padding: 1%;

}

.search-bar:focus {
  outline: none;
  border-color: #007bff;
}

/* Grid layout for videos */
.videos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); /* Adjusted for consistency */
  gap: 20px;
  padding: 0 40px; /* Adjusted padding for a tighter layout */
}

/* Styles for individual video cards */
.video-card {
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  height: auto; /* Adjusted for flexible height */
}

.video-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Styles for video images within cards */
.video-image {
  width: 100%; /* Adjusted to fill the card width */
  height: 180px; /* Consistent height for all images */
  object-fit: cover;
}

/* Content within video cards */
.video-content {
  padding: 20px;
}

.video-title-list {
  font-size: 1.0em;
  margin: 0;
  color: #333;
}

/* Additional styles from CoursesPage.css kept for button and title styling */
.course-list-title, .video-list-title {
  font-size: 2em;
  color: #333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.add-to-cart-button, .course-link {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover, .course-link:hover {
  background-color: #0056b3;
}

.add-to-cart-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
