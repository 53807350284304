/* Reset and Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    font-family: 'Arial', sans-serif;
    background: #f4f5f7;
    color: #333;
}

/* Outer Container */
.outer-quiz-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to right, #e3f2fd, #ede7f6);
    padding: 30px;
}

/* Quiz Container */
.quiz-container {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 40px;
    max-width: 800px;
    width: 100%;
    text-align: center;
    transition: transform 0.3s ease;
    overflow-y: auto;  /* Enable scrolling */

}

.quiz-container:hover {
    transform: scale(1.02);
}

/* Quiz Header */
.quiz-heading {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

/* Question Section */
.quiz-question-container {
    margin-bottom: 30px;
}

.quiz-question {
    font-size: 1.8rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 15px;
}

/* Choices */
.choices-list {
    display: grid;
    gap: 15px;
}

.choice-item {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 1.2rem;
    background: #f9f9f9;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.choice-item:hover {
    background: #eceff1;
    border-color: #90caf9;
}

.choice-item input[type="radio"],
.choice-item input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.3);
}

/* Submit Button */
.submit-answer-button {
    display: inline-block;
    width: 100%;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(to right, #5c6bc0, #3949ab);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: background 0.3s ease;
}

.submit-answer-button:hover {
    background: linear-gradient(to right, #3949ab, #303f9f);
}

/* Connected Users */
.connected-users-container {
    padding: 15px;
    border-top: 2px solid #f0f0f0;
}

.user-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    justify-content: center;
}

.user-card {
    background: #fff;
    border: 2px solid #ddd;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    width: auto;
    transition: transform 0.3s, box-shadow 0.3s;
}

.user-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.user-card-username {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.user-card.submitted {
    background: #e8f5e9;
    border: 2px solid #81c784;
}

.submission-indicator {
    font-size: 1rem;
    color: #388e3c;
    margin-top: 8px;
}

/* Countdown Animation */
.countdown {
    font-size: 6rem;
    font-weight: bold;
    color: #3949ab;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

/* Loading & Messages */
.loading, .waiting-message, .error {
    font-size: 1.8rem;
    font-weight: bold;
    color: #757575;
    padding: 20px 0;
}

/* Restart Game Button */
.restart-quiz-button {
    background: linear-gradient(to right, #f44336, #d32f2f);
    color: white;
    padding: 12px 24px;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.restart-quiz-button:hover {
    background: linear-gradient(to right, #d32f2f, #b71c1c);
}

/* Placeholder */
.placeholder {
    font-size: 1.5rem;
    color: #999;
    margin-top: 2rem;
}
.correct-answer {
    border: 3px solid green;
    background-color: #d4edda;
    transition: all 0.5s ease;
  }
  

  .wrong-answer {
    border: 3px solid red; /* Red border to indicate incorrect answer */
    background-color: #f8d7da; /* Light red background for clarity */
    transition: all 0.5s ease; /* Smooth transition for visual feedback */
  }
  .quiz-over-container {
    padding: 2rem;
    text-align: center;
}

.quiz-over h2 {
    color: #4CAF50;
    font-size: 3rem;
    font-weight: bold;
}

.quiz-summary {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 1rem;
}

.ranking-list {
    margin: 2rem 0;
}

.ranking-item {
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    padding: 0.8rem 1.2rem;
    border-radius: 8px;
    margin: 0.5rem 0;
}

.rank-number {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.player-name {
    font-size: 1.2rem;
    color: #555;
}

.player-score {
    font-size: 1.2rem;
    font-weight: bold;
    color: #222;
}

.restart-quiz-button {
    background: #4CAF50;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.restart-quiz-button:hover {
    background: #45A049;
}

.feedback-container {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .feedback-message {
    margin-bottom: 10px;
    padding: 10px;
    border-left: 5px solid #3498db;
    background-color: #ffffff;
    border-radius: 5px;
  }
  
  .feedback-message p {
    margin: 0 0 5px;
  }
  
  .feedback-message strong {
    font-weight: bold;
  }

 
  .toggle-chat-button:hover {
    background-color: #2978b5;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Floating Chat Window */
  .floating-chat {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 300px;
    height: 400px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    transition: all 0.3s ease;
  }
  
  .floating-chat.closed {
    display: none;
  }
  
  .floating-chat .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .floating-chat .chat-messages .message-bubble {
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 15px;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  .floating-chat .chat-messages .message-bubble.sent {
    background-color: #3498db;
    color: white;
    align-self: flex-end;
  }
  
  .floating-chat .chat-messages .message-bubble.received {
    background-color: #eaeaea;
    align-self: flex-start;
  }
  
  .floating-chat .chat-input {
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: white;
  }
  
  .floating-chat .chat-input textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 8px;
    resize: none;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .outer-quiz-container {
    display: flex;
    flex-direction: row;
    height: auto; /* Slightly reduced height for better spacing */
    margin: 20px; /* Add margin to prevent edge cramping */
    background-color: #f4f4f9; /* Subtle, light background color */
    border-radius: 15px; /* Rounded edges */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    border: 1px solid #ddd; /* Optional subtle border for better definition */
    overflow-y: auto;  /* Enable scrolling */

}

.quiz-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #f9f9f9;
    padding: 10px;
}

.connected-users-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.floating-chat {
    
    flex-direction: row;
    border-left: 1px solid #ddd;
    padding: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-height: 30vh;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
}



.mini-chat .chat-header {
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: #3498db;
    padding: 5px;
    text-align: center;
}

/* Chat AI Class */
.AI-button {
    display: flex;
    flex-direction: column; /* Stack toggle button and chat vertically */
    margin-bottom: 15%;
    max-width: 300px; /* Limit width */
    width: 100%; /* Responsive for smaller screens */
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-left:5% ;
}
.AI-button-child1{
    flex:1
}

.AI-button-child2{
    flex:1
}
/* Chat AI Button */
 .toggle-chat-button {
    flex: 1;
    width: 100%; /* Full width of the container */
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px 5px 0 0; /* Rounded top corners */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.AI-button .toggle-chat-button:hover {
    background-color: #2978b5;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}


.AI-button .floating-chat.closed {
    max-height: 0; /* Collapse when closed */
    opacity: 0;
    overflow: hidden;
}

/* Chat Messages */
.chat-ai .floating-chat .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #ffffff;
}

.mini-chat .chat-messages {
    flex: 1 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translateY(20px); /* Moves the element down by 20px */
}

.instructional-container {
    padding: 15px;
    background-color: #ffffff; /* White background for contrast */
    border-radius: 10px;
    margin-bottom: 20px; /* Space between instruction and content */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .instructional-container h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .instructional-container p {
    font-size: 16px;
    color: #666666;
    line-height: 1.5;
  }
  
  .make-test-button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .make-test-button:hover {
    background-color: #2176ba; /* Slightly darker blue on hover */
  }
  
  .no-topic-selected {
    text-align: center;
    color: #ff6b6b;
    font-size: 16px;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    /* Outer Container */
    .outer-quiz-container {
      display: flex;
      flex-direction: column;
      height: auto; /* Slightly reduced height for better spacing */
      overflow-y: auto; /* Allow scrolling if content exceeds viewport */
      background-color: #f4f4f9;
      border-radius: 15px; /* Rounded edges */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow */
      padding: 0; /* No padding */
      margin-bottom: 10%;
    }
  
    /* Quiz Container */
    .quiz-container {
      flex: 1; /* Take up remaining space */
      overflow-y: auto; /* Allow scrolling within the quiz if needed */
      padding: 10px; /* Ensure consistent padding */
      background-color: #f9f9f9;
      border-bottom: 1px solid #ddd; /* Separator between quiz and chat */
    }
  
    /* Chat Container */
    .floating-chat {
      position: relative; /* Ensure it's part of the document flow */
      width: 100%; /* Full width */
      max-height: 300px; /* Limit height */
      background-color: white;
      border-top: 1px solid #ddd; /* Separator line */
      display: flex;
      flex-direction: column;
      overflow-y: auto; /* Allow chat content to scroll */
    }
  
    /* Chat Messages */
    .floating-chat .chat-messages {
      flex: 1; /* Take up available space */
      overflow-y: auto; /* Enable scrolling for chat messages */
      padding: 10px;
      background-color: #ffffff;
    }
  
    /* Chat Input */
    .floating-chat .chat-input textarea {
      width: 100%;
      border: none;
      padding: 10px;
      resize: none;
      font-size: 14px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    /* Buttons */
    .submit-answer-button, .restart-quiz-button {
      font-size: 1rem; /* Smaller font size */
      padding: 10px; /* Adjust padding */
      width: 100%; /* Full-width buttons */
    }
  
    /* AI Button */
    .AI-button {
      max-width: 100%; /* Full width */
      margin: 0 auto; /* Center horizontally */
      margin-top: 10px; /* Space above */
    }
  
    .toggle-chat-button {
      font-size: 14px; /* Smaller font size */
      padding: 8px; /* Less padding */
    }
  }
  
  .choice-image {
    display: block;
    max-width: 40%; /* Adjust as needed */
    max-height: 40%;
    object-fit: cover;
    margin: 0.5rem 0; /* Optional margin */
    margin-left: 30%;
  }
  
  @media (max-width: 768px) {
    .choice-image {
      max-width: 40%;
      max-height: 40%;
    }
  }
  