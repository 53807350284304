.mathContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .addends {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .appleContainer {
    display: flex;
    overflow-x: scroll;
    height: 60px;
  }
  
  .app-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 1em;
  }
  
  .app-description {
    margin-bottom: 1em;
    font-size: 1em;
  }
  