/* CoursesPage.css */

.course-container {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 20px auto;
  max-width: 1200px;
}

.course-list-title {
  font-size: 2em;
  color: #333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.courses-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.course-card {
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1 1 calc(300px - 20px);
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 1px solid #eaeaea;
}

.course-content {
  padding: 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-title {
  font-size: 1.5em;
  margin: 0;
  color: #333;
}

.course-description {
  font-size: 1em;
  color: #666;
  margin: 10px 0;
}

.add-to-cart-button,
.course-link {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover,
.course-link:hover {
  background-color: #0056b3;
}

.add-to-cart-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
